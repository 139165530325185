
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OTermsOfServiceList: () => import('@/components/organisms/globalTools/terms/termsOfService/o-terms-of-service-list.vue')},

  setup(){

    return {}
  }
})
